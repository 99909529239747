<template>
  <list :list="alertSettingsList" :header-list="alertSettingsHeaderList" :has-scroll-list="true">
    <li slot="list-body" v-for="(alertSettingsItem, index) in alertSettingsList" :key="index">
      <span>
        {{ index + 1 }}
      </span>
      <span>
        <input-box
          type="text"
          :id="`alert-code-${index}`"
          v-model="alertSettingsItem.issueCode"
          autocomplete="off"
          :is-flex="true"
        />
      </span>
      <span>
        <drop-down-box
          :id="`alert-type-${index}`"
          :value="alertSettingsItem.alertType"
          placeholder="Web 알림"
          :optionItemsData="Object.keys(alertSettingsOptionNames)"
          :optionNames="alertSettingsOptionNames"
          @selectedOption="onSelectedOption(alertSettingsItem, ...arguments)"
        />
      </span>
      <span>
        <input-box
          type="text"
          :id="`alert-title-${index}`"
          :maxlength="10"
          v-model="alertSettingsItem.title"
          autocomplete="off"
          :is-flex="true"
        />
      </span>
      <span>
        <input-box
          type="text"
          :id="`alert-description-${index}`"
          :maxlength="50"
          v-model="alertSettingsItem.description"
          autocomplete="off"
          :is-flex="true"
        />
      </span>
      <span>
        <photo
          v-if="alertSettingsItem.rscFileId"
          :isShowDeleteButton="true"
          @delete="onDeletePhoto(alertSettingsItem)"
          :imgSrc="alertSettingsItem.rscFileUrl"
        />
        <file-basic
          v-else
          accept=".jpg, .png"
          :id="`alert-file-${index}`"
          class="text-button text-button-gray"
          @change="onSelectFile($event, alertSettingsItem)"
        >
          추가
        </file-basic>
      </span>
      <span data-icon="highlight_off" @click="onDeleteAlertItem(index)">
        <vue-material-icon name="highlight_off" :size="20" />
      </span>
    </li>
    <p slot="no-list-description" class="font-16">'행 추가' 버튼을 클릭하여 알림 설정을 추가하세요.</p>
  </list>
</template>

<script>
import List from '@/component/list/List';
import ListHeaderData from '../../equipmentGroupsData/ListHeaderData.json';
import InputBox from '@/component/input/InputBox';
import DropDownBox from '@/component/dropDown/DropDownBox';
import Photo from '@/component/photoView/Photo';

export default {
  name: 'AlertSettings',
  props: ['alertSettingsList'],
  data() {
    return {
      alertSettingsHeaderList: ListHeaderData,
      alertSettingsOptionNames: {
        WEB: 'Web 알림',
        APP: 'App. 알림',
        BOTH: '둘 다 알림',
        STOP: '알림 중단',
      },
    };
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
  methods: {
    onSelectedOption(alertSettingsItem, selectedAlertOption) {
      alertSettingsItem.alertType = selectedAlertOption;
    },
    onSelectFile(e, alertSettingsItem) {
      this.$emit('selectUploadData', { event: e, alertSettingsItem, type: 'photo' });
    },
    onDeleteAlertItem(itemIndex) {
      this.$emit('deleteAlertItem', itemIndex);
    },
    onDeletePhoto(alertSettingsItem) {
      this.$emit('deleteUploadData', 'photo', alertSettingsItem);
    },
  },
  components: { List, InputBox, DropDownBox, Photo },
};
</script>

<style scoped lang="scss">
@import 'AlertSettingsList';
</style>
