var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "main-section bg-white has-no-scroll" },
    [
      _c("main-section-header", { attrs: { title: "설비그룹 등록" } }),
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("subtitle", { attrs: { subtitle: "설비그룹 정보" } }),
          _c(
            "ul",
            {
              staticClass: "detail-list",
              class: { "factory-list": _vm.isFactoryComp },
            },
            _vm._l(_vm.equipGroupInfo, function (eqpInfo, index) {
              return _c(
                "detail-list-row",
                {
                  key: index,
                  attrs: {
                    "is-register": true,
                    info: eqpInfo,
                    inputKey: eqpInfo.key,
                    "input-value": _vm.equipmentGroupData[eqpInfo.key],
                    "text-count": eqpInfo.textCount,
                    maxlength: eqpInfo.maxlength,
                    placeholder: eqpInfo.placeholder,
                    fileName: _vm.fileName,
                  },
                  on: {
                    selectUploadData: _vm.onSelectUploadData,
                    deleteUploadData: _vm.onDeleteUploadData,
                    saveChangeValue: _vm.onSaveInputValue,
                    createAlertSettingsItem: _vm.onCreateAlertSettingsItem,
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "label",
                      class: { "label-required": eqpInfo.isRequired },
                      attrs: { slot: "label" },
                      slot: "label",
                    },
                    [_vm._v(" " + _vm._s(eqpInfo.label) + " ")]
                  ),
                ]
              )
            }),
            1
          ),
          _c(
            "div",
            { staticClass: "mb-50 ml-20 mr-20" },
            [
              _c("alert-settings-list", {
                attrs: {
                  "alert-settings-list": _vm.equipmentGroupData.alertSettings,
                },
                on: {
                  deleteAlertItem: _vm.onDeleteAlertItem,
                  selectUploadData: _vm.onSelectUploadData,
                  deleteUploadData: _vm.onDeleteUploadData,
                },
              }),
            ],
            1
          ),
          _c(
            "list-bottom-button-box",
            [
              _c(
                "button-basic",
                {
                  attrs: {
                    slot: "button",
                    color: "gray-border-1",
                    size: "m",
                    width: 150,
                    disabled: _vm.isLoading,
                  },
                  on: { click: _vm.onMoveEquipGroupList },
                  slot: "button",
                },
                [_vm._v(" 취소 ")]
              ),
              _c(
                "button-basic",
                {
                  staticClass: "mr-20",
                  attrs: {
                    slot: "button",
                    color: "primary",
                    size: "m",
                    width: 150,
                    disabled: !_vm.isFilledAllRequiredField,
                    "loading-dot": _vm.isLoading,
                  },
                  on: { click: _vm.onSaveEquipGroup },
                  slot: "button",
                },
                [_vm._v(" 등록 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }