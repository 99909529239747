var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "list",
    {
      attrs: {
        list: _vm.alertSettingsList,
        "header-list": _vm.alertSettingsHeaderList,
        "has-scroll-list": true,
      },
    },
    [
      _vm._l(_vm.alertSettingsList, function (alertSettingsItem, index) {
        return _c(
          "li",
          { key: index, attrs: { slot: "list-body" }, slot: "list-body" },
          [
            _c("span", [_vm._v(" " + _vm._s(index + 1) + " ")]),
            _c(
              "span",
              [
                _c("input-box", {
                  attrs: {
                    type: "text",
                    id: "alert-code-" + index,
                    autocomplete: "off",
                    "is-flex": true,
                  },
                  model: {
                    value: alertSettingsItem.issueCode,
                    callback: function ($$v) {
                      _vm.$set(alertSettingsItem, "issueCode", $$v)
                    },
                    expression: "alertSettingsItem.issueCode",
                  },
                }),
              ],
              1
            ),
            _c(
              "span",
              [
                _c("drop-down-box", {
                  attrs: {
                    id: "alert-type-" + index,
                    value: alertSettingsItem.alertType,
                    placeholder: "Web 알림",
                    optionItemsData: Object.keys(_vm.alertSettingsOptionNames),
                    optionNames: _vm.alertSettingsOptionNames,
                  },
                  on: {
                    selectedOption: function ($event) {
                      var i = arguments.length,
                        argsArray = Array(i)
                      while (i--) argsArray[i] = arguments[i]
                      return _vm.onSelectedOption.apply(
                        void 0,
                        [alertSettingsItem].concat(argsArray)
                      )
                    },
                  },
                }),
              ],
              1
            ),
            _c(
              "span",
              [
                _c("input-box", {
                  attrs: {
                    type: "text",
                    id: "alert-title-" + index,
                    maxlength: 10,
                    autocomplete: "off",
                    "is-flex": true,
                  },
                  model: {
                    value: alertSettingsItem.title,
                    callback: function ($$v) {
                      _vm.$set(alertSettingsItem, "title", $$v)
                    },
                    expression: "alertSettingsItem.title",
                  },
                }),
              ],
              1
            ),
            _c(
              "span",
              [
                _c("input-box", {
                  attrs: {
                    type: "text",
                    id: "alert-description-" + index,
                    maxlength: 50,
                    autocomplete: "off",
                    "is-flex": true,
                  },
                  model: {
                    value: alertSettingsItem.description,
                    callback: function ($$v) {
                      _vm.$set(alertSettingsItem, "description", $$v)
                    },
                    expression: "alertSettingsItem.description",
                  },
                }),
              ],
              1
            ),
            _c(
              "span",
              [
                alertSettingsItem.rscFileId
                  ? _c("photo", {
                      attrs: {
                        isShowDeleteButton: true,
                        imgSrc: alertSettingsItem.rscFileUrl,
                      },
                      on: {
                        delete: function ($event) {
                          return _vm.onDeletePhoto(alertSettingsItem)
                        },
                      },
                    })
                  : _c(
                      "file-basic",
                      {
                        staticClass: "text-button text-button-gray",
                        attrs: {
                          accept: ".jpg, .png",
                          id: "alert-file-" + index,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.onSelectFile($event, alertSettingsItem)
                          },
                        },
                      },
                      [_vm._v(" 추가 ")]
                    ),
              ],
              1
            ),
            _c(
              "span",
              {
                attrs: { "data-icon": "highlight_off" },
                on: {
                  click: function ($event) {
                    return _vm.onDeleteAlertItem(index)
                  },
                },
              },
              [
                _c("vue-material-icon", {
                  attrs: { name: "highlight_off", size: 20 },
                }),
              ],
              1
            ),
          ]
        )
      }),
      _c(
        "p",
        {
          staticClass: "font-16",
          attrs: { slot: "no-list-description" },
          slot: "no-list-description",
        },
        [_vm._v("'행 추가' 버튼을 클릭하여 알림 설정을 추가하세요.")]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }