<template>
  <section class="main-section bg-white has-no-scroll">
    <main-section-header title="설비그룹 등록" />
    <div class="container">
      <subtitle subtitle="설비그룹 정보" />
      <ul class="detail-list" :class="{ 'factory-list': isFactoryComp }">
        <detail-list-row
          :is-register="true"
          v-for="(eqpInfo, index) in equipGroupInfo"
          :key="index"
          :info="eqpInfo"
          :inputKey="eqpInfo.key"
          :input-value="equipmentGroupData[eqpInfo.key]"
          :text-count="eqpInfo.textCount"
          :maxlength="eqpInfo.maxlength"
          :placeholder="eqpInfo.placeholder"
          :fileName="fileName"
          @selectUploadData="onSelectUploadData"
          @deleteUploadData="onDeleteUploadData"
          @saveChangeValue="onSaveInputValue"
          @createAlertSettingsItem="onCreateAlertSettingsItem"
        >
          <span slot="label" class="label" :class="{ 'label-required': eqpInfo.isRequired }">
            {{ eqpInfo.label }}
          </span>
        </detail-list-row>
      </ul>
      <div class="mb-50 ml-20 mr-20">
        <alert-settings-list
          :alert-settings-list="equipmentGroupData.alertSettings"
          @deleteAlertItem="onDeleteAlertItem"
          @selectUploadData="onSelectUploadData"
          @deleteUploadData="onDeleteUploadData"
        />
      </div>
      <list-bottom-button-box>
        <button-basic
          slot="button"
          color="gray-border-1"
          size="m"
          :width="150"
          @click="onMoveEquipGroupList"
          :disabled="isLoading"
        >
          취소
        </button-basic>
        <button-basic
          slot="button"
          color="primary"
          size="m"
          :width="150"
          @click="onSaveEquipGroup"
          :disabled="!isFilledAllRequiredField"
          class="mr-20"
          :loading-dot="isLoading"
        >
          등록
        </button-basic>
      </list-bottom-button-box>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import MainSectionHeader from '@/component/mainSectionHeader/MainSectionHeader';
import Subtitle from '@/component/subtitle/Subtitle';
import DetailListRow from '@/component/detailListRow/DetailListRow';
import fileUpload from '@/asset/js/fileUpload';
import AlertSettingsList from '@/view/equipmentGroups/equipmentGroupRegister/alertSettingsList/AlertSettingsList';
import ListBottomButtonBox from '@/component/list/ListBottomButtonBox';
import manufacturerInfo from './equipmentGroupRegisterData/ManufacturerInfo.json';
import factoryInfo from './equipmentGroupRegisterData/FactoryInfo.json';

export default {
  name: 'EquipmentGroupRegister',
  props: [],
  data() {
    return {
      equipGroupInfo: '',
      equipmentGroupData: {
        groupName: '',
        manualUrl: '',
        rscId: '',
        alertSettings: [],
      },
      selectedAlertSettingsItem: null,
      allFilename: '',
      fileBase64: '',
      imageUrl: '',
      uploadType: '',
      photoId: '',
      fileName: '',
      fileRscData: {},
      isLoading: false,
    };
  },
  computed: {
    ...mapState('users', ['currentCompany']),
    ...mapGetters('users', ['isFactoryComp']),
    isFilledAllRequiredField() {
      let isFilledAll = true;
      Object.values(this.equipGroupInfo).forEach((info) => {
        if (info.isRequired && !this.equipmentGroupData[info.key]) {
          isFilledAll = false;
        } else if (info.key === 'alertSettings') {
          this.equipmentGroupData[info.key].forEach((alert) => {
            if (!alert.description || !alert.issueCode || !alert.title) {
              isFilledAll = false;
            }
          });
        }
      });
      return isFilledAll;
    },
  },
  created() {
    switch (this.currentCompany) {
      case 'M':
        this.equipGroupInfo = manufacturerInfo;
        break;
      case 'F':
        this.equipGroupInfo = factoryInfo;
        break;
    }
  },
  mounted() {},
  watch: {
    fileBase64() {
      if (this.fileBase64) {
        const rscObj = {
          rscName: this.allFilename,
          rscValue: this.fileBase64,
        };
        switch (this.uploadType) {
          case 'file':
            this.fileRscData = rscObj;
            this.fileName = this.allFilename;
            this.initializeManualFile();
            break;
          case 'photo':
            this.postPhotoRsc(rscObj);
            this.initializeManualFile();
            break;
        }
      }
    },
  },
  methods: {
    ...mapActions('factoryEqpGroups', [
      'POST_FACTORY_EQP_GROUP',
      'POST_FACTORY_EQP_GROUP_RSC',
      'DELETE_FACTORY_EQP_GROUP_RSC',
    ]),
    ...mapActions('manufacturerEqpGroups', [
      'POST_MANUFACTURER_EQP_GROUP',
      'POST_MANUFACTURER_EQP_GROUP_RSC',
      'DELETE_MANUFACTURER_EQP_GROUP_RSC',
    ]),
    ...mapActions('factoryAlert', ['POST_FACTORY_ALERT_RSC', 'DELETE_FACTORY_ALERT_RSC']),
    ...mapActions('manufacturerAlert', ['POST_MANUFACTURER_ALERT_RSC', 'DELETE_MANUFACTURER_ALERT_RSC']),
    onSaveInputValue(inputKey, changedValue) {
      this.equipmentGroupData[inputKey] = typeof changedValue === 'string' ? changedValue.trim() : changedValue;
    },
    onSelectUploadData({ event, alertSettingsItem = null, type }) {
      if (alertSettingsItem) this.selectedAlertSettingsItem = alertSettingsItem;
      this.uploadType = type;
      fileUpload(this, event.target, true, false);
    },
    initializeManualFile() {
      this.allFilename = '';
      this.fileBase64 = '';
      const upFileManual = document.getElementById('upFileManual');
      if (upFileManual) {
        upFileManual.value = '';
      }
    },
    async postPhotoRsc(rscObj) {
      switch (this.currentCompany) {
        case 'M':
          this.photoId = await this.POST_MANUFACTURER_ALERT_RSC(rscObj);
          break;
        case 'F':
          this.photoId = await this.POST_FACTORY_ALERT_RSC(rscObj);
          break;
      }
      this.selectedAlertSettingsItem.rscFileId = this.photoId;
      this.selectedAlertSettingsItem.rscFileUrl = this.imageUrl;
    },
    onDeleteUploadData(uploadType, alertSettingsItem) {
      this.selectedAlertSettingsItem = alertSettingsItem;
      switch (uploadType) {
        case 'file':
          this.equipmentGroupData.rscId = '';
          this.fileName = '';
          break;
        case 'photo':
          this.deletePhotoRsc(alertSettingsItem.rscFileId);
          this.photoId = '';
          break;
      }
      this.allFilename = '';
    },
    async deletePhotoRsc(rscFileId) {
      switch (this.currentCompany) {
        case 'M':
          await this.DELETE_MANUFACTURER_ALERT_RSC(rscFileId);
          break;
        case 'F':
          await this.DELETE_FACTORY_ALERT_RSC(rscFileId);
          break;
      }
      this.selectedAlertSettingsItem.rscFileId = '';
      this.selectedAlertSettingsItem.rscFileUrl = '';
    },
    onMoveEquipGroupList() {
      this.$router.push(`/equipment-groups`).catch((err) => err);
    },
    async onSaveEquipGroup() {
      try {
        this.isLoading = true;
        if (Object.keys(this.fileRscData).length !== 0) {
          await this.postFileRsc();
        }
        await this.postEquipGroup();
        this.isLoading = false;
        this.$router.push(`/equipment-groups`).catch((err) => err);
      } finally {
        this.isLoading = false;
      }
    },
    async postFileRsc() {
      switch (this.currentCompany) {
        case 'M':
          this.equipmentGroupData.rscId = await this.POST_MANUFACTURER_EQP_GROUP_RSC(this.fileRscData);
          break;
        case 'F':
          this.equipmentGroupData.rscId = await this.POST_FACTORY_EQP_GROUP_RSC(this.fileRscData);
          break;
      }
    },
    async postEquipGroup() {
      switch (this.currentCompany) {
        case 'M':
          await this.POST_MANUFACTURER_EQP_GROUP(this.equipmentGroupData);
          break;
        case 'F':
          await this.POST_FACTORY_EQP_GROUP(this.equipmentGroupData);
          break;
      }
    },
    onCreateAlertSettingsItem() {
      const newAlertSettingsItem = this.getEmptyAlertSettingsItem();
      this.equipmentGroupData.alertSettings.push(newAlertSettingsItem);
    },
    getEmptyAlertSettingsItem() {
      return {
        issueCode: null,
        alertType: 'WEB',
        title: '',
        description: '',
        rscFileId: 0,
      };
    },
    onDeleteAlertItem(AlertItemIndex) {
      this.equipmentGroupData.alertSettings.splice(AlertItemIndex, 1);
    },
  },
  components: {
    MainSectionHeader,
    Subtitle,
    DetailListRow,
    AlertSettingsList,
    ListBottomButtonBox,
  },
};
</script>

<style scoped lang="scss">
@import 'EquipmentGroupRegister';
</style>
